import React, { useEffect, useState, useRef } from 'react';
import { Modal, Form, Input, Button, Alert, Upload, Radio } from 'antd';
// import { RadioChangeEvent } from 'antd/lib/radio';
import { PlusOutlined } from '@ant-design/icons';
import { uploadPdf, uploadZip } from '../../store/actions/adminActionCreators';
// import { getpdf } from '../../store/actions/customerActionCreators';
// import * as adminActions from '../../store/actions/adminActions';
import { useAppDispatch, useAppSelector } from '../../store/reduxHooks';
import { maxCharacterValidation } from '../../util/errorhandlers.util';

interface IProps {
  showUplaodPricingPdfModal: boolean;
  handleClose: () => void;
}

const PricingPdf = (props: IProps) => {
  const [pricingPdfForm] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string | null>(null);
  const [serverSuccess, setServerSuccess] = useState<string | null>(null);
  // const { fileDetails } = useAppSelector(state => state.userReducer);
  const [fileList, setFileList] = useState<any>([]);
  const [errors, setErrors] = useState<string | null>(null);
  const [isPdf, setIsPdf] = useState<boolean | null>();
  // const dispatch = useAppDispatch();
  // const inputRef: any = useRef();

  const handleClose = () => {
    props.handleClose();
    setServerError(null);
    setServerSuccess(null);
    // window.setTimeout(() => {
    //   inputRef.current.value = '';
    // });
  };

  // const fetchCurrentPdf = async () => {
  //   const [response, err]: Array<any> = await getpdf();
  //   if (response) {
  //     if (!response.data.succeeded) {
  //       setServerError(response.data.data.errors[0]);
  //       setLoading(false);
  //       return;
  //     }
  //     dispatch({ type: adminActions.PDF_DETAILS, payload: response.data.data });
  //     setLoading(false);
  //   }
  //   if (err) {
  //     if (err.response.data.succeeded) {
  //       setServerError(err.response.data.data.errors[0]);
  //     } else {
  //       setServerError(err.response.data.message);
  //     }
  //     setLoading(false);
  //     return;
  //   }
  // };

  // useEffect(() => {
  //   fetchCurrentPdf();
  // }, [props.showUplaodPricingPdfModal]);

  useEffect(() => {
    // pricingPdfForm.setFieldsValue({ name: '', isPdfFile: '' });
    pricingPdfForm.resetFields();
    setFileList([]);
  }, [props.showUplaodPricingPdfModal]);

  const fileChangeHandler = (info: any) => {
    setErrors(null);
    setFileList(info.fileList);
  };

  const fileTypeChangeHandler = (e: any) => {
    if (e.target.value === true) {
      setIsPdf(true);
      console.log(isPdf);
    }
    if (e.target.value === false) {
      setIsPdf(false);
      console.log(isPdf);
    }
  };

  const updatePdfHandler = async (values: any) => {
    setServerError(null);
    setServerSuccess(null);
    setLoading(true);
    const formData = new FormData();
    formData.append('name', values.name);
    if (isPdf) {
      fileList && fileList[0] ? formData.append('file', fileList[0].originFileObj) : setServerError('');
    } else {
      fileList && fileList[0] ? formData.append('file', fileList[0].originFileObj) : setServerError('');
    }

    const [response, err]: Array<any> = isPdf ? await uploadPdf(formData) : await uploadZip(formData);

    if (response) {
      if (!response.data.succeeded) {
        setServerError(response.data.data.errors[0]);
        setLoading(false);
        return;
      }

      setServerSuccess(response.data.message);

      pricingPdfForm.resetFields();
      setFileList([]);
      setLoading(false);
      // window.setTimeout(() => {
      //   setLoading(false);
      //   handleClose();
      // }, 1000);
    }

    if (err) {
      if (err.response.data.succeeded) {
        setServerError(err.response.data.data.errors[0]);
      }
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={props.showUplaodPricingPdfModal}
      title=" Upload Pricing PDF/ZIP File"
      centered
      width={700}
      onCancel={handleClose}
      footer={[
        <Button onClick={handleClose}>Close</Button>,
        <Button
          loading={loading}
          form="pricingPdfForm"
          type="primary"
          key="submit"
          htmlType="submit"
          disabled={loading}
        >
          Update File
        </Button>
      ]}
    >
      <div className="form__wrapper">
        <div className="alert__wrapper" style={{ marginBottom: '10px' }}>
          {serverError && <Alert message={serverError} type="error" closable showIcon />}
          {serverSuccess && <Alert message={serverSuccess} type="success" closable showIcon />}
        </div>
        <Form
          name="Pricing Pdf"
          form={pricingPdfForm}
          onFinish={updatePdfHandler}
          scrollToFirstError
          id="pricingPdfForm"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Name is required'
              },
              {
                pattern: new RegExp(/^[a-zA-Z0-9 ]+$/i),
                message: 'name must include letters and numbers only'
              },
              {
                validator: maxCharacterValidation
              }
            ]}
          >
            <Input disabled={loading} />
          </Form.Item>

          <Form.Item
            label="File Type"
            name="isPdfFile"
            rules={[
              {
                required: true,
                message: 'File type is required'
              }
            ]}
          >
            <Radio.Group onChange={fileTypeChangeHandler} disabled={loading}>
              <Radio value={true}>PDF</Radio>
              <Radio value={false}>ZIP</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            style={{ marginLeft: '112px', width: '300px' }}
            name="file"
            rules={[
              {
                required: true,
                message: 'File type is required'
              }
            ]}
          >
            <Upload
              className="full__width"
              listType="picture"
              beforeUpload={() => false}
              fileList={fileList}
              onChange={fileChangeHandler}
              accept=".pdf,.zip"
              maxCount={1}
              disabled={loading}
            >
              {fileList.length === 0 ? (
                <Button type="dashed" icon={<PlusOutlined />} style={{ height: '50px' }}>
                  Click to Upload File
                </Button>
              ) : null}
            </Upload>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default PricingPdf;
