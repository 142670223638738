import { ILoginData } from "./../interfaces/auth.interface";
import { getUserFromStorage } from "./localStorage.util";

export const isUserLoggedIn = () => {
  if (!!getUserFromStorage()) {
    return true;
  }
  return false;
};

export const parseUser = (): ILoginData => {
  return JSON.parse(getUserFromStorage() || "");
};
