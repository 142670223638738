import {
  BulbOutlined,
  CloseOutlined,
  LogoutOutlined,
  // MenuFoldOutlined,
  MenuUnfoldOutlined,
  HeartOutlined,
  SearchOutlined,
  LockOutlined,
  KeyOutlined,
  QuestionCircleOutlined,
  UserOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { Button, Dropdown, Input, Menu, Switch, Avatar } from 'antd';
// import {useSelector} from 'react-redux';
// import MenuItem from "antd/lib/menu/MenuItem";
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../assets/images/logo2.png';
// import Profile from "../assets/images/profile.jpg";
import { ThemeList } from '../enum/enum';
// import CheckPermission from "../hoc/CheckPermission";
import { clearLocalStorage, getTheme, getUserFromStorage, setTheme } from '../util/localStorage.util';
import PricingPdf from '../pages/Pricing-Pdf/PricingPdf';
import { useAppSelector } from '../store/reduxHooks';

interface IProps {
  changeTheme: () => void;
  onClose: () => void;
  showDrawer: () => void;
  sidebarColllapse: boolean;
}

const storageInfo = getUserFromStorage();

const Navbar = (props: IProps) => {
  const history = useHistory();
  // const counter = useSelector((state: any) => state.user)
  // console.log(counter)

  // const { pdfDetails } = useAppSelector(state => state.userReducer);

  const [showResponsiveSearch, setShowResponsiveSearch] = useState<boolean>(false);
  const [showUplaodPricingPdfModal, setShowUplaodPricingPdfModal] = useState<boolean>(false);

  const handleResponsiveSearch = () => {
    setShowResponsiveSearch(true);
  };

  const handleResponsiveSearchClose = () => {
    setShowResponsiveSearch(false);
  };

  const signOutHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    clearLocalStorage();
    if (storageInfo) {
      history.push(JSON.parse(storageInfo).isAdmin ? '/admin/login' : '/login');
    } else {
      history.push('/login');
    }
  };

  const uploadPricingPdfHandler = () => {
    setShowUplaodPricingPdfModal(true);
  };
  const handleClose = () => {
    setShowUplaodPricingPdfModal(false);
  };

  const changeThemeApp = () => {
    if (getTheme() && getTheme() === ThemeList.Light) {
      setTheme(ThemeList.DARK);
    } else {
      setTheme(ThemeList.Light);
    }
    props.changeTheme();
    window.location.href = window.location.href;
    return false;
  };

  // const menuNotification = (
  //   <Menu className="notification__dropdown">
  //     <Menu.Item>
  //       <div className="notification__header">
  //         <h4>Notification</h4>
  //         <Button type="text" className="clear__btn">
  //           Clearn
  //         </Button>
  //       </div>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <div className="notification__list">
  //         <Avatar size={40}>
  //           <img src={Profile} alt="" />
  //         </Avatar>
  //         <div className="comment__section">
  //           <span className="commentor__name">Erin Gonzales </span>
  //           <span className="comment__text">has comment on your board</span>
  //         </div>
  //         <span className="comment__time">7:57PM</span>
  //       </div>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <div className="notification__list">
  //         <Avatar size={40}>
  //           <img src={Profile} alt="" />
  //         </Avatar>
  //         <div className="comment__section">
  //           <span className="commentor__name">Erin Gonzales </span>
  //           <span className="comment__text">has comment on your board</span>
  //         </div>
  //         <span className="comment__time">7:57PM</span>
  //       </div>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <div className="notification__list">
  //         <Avatar size={40}>
  //           <img src={Profile} alt="" />
  //         </Avatar>
  //         <div className="comment__section">
  //           <span className="commentor__name">Erin Gonzales </span>
  //           <span className="comment__text">has comment on your board</span>
  //         </div>
  //         <span className="comment__time">7:57PM</span>
  //       </div>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <div className="notification__list">
  //         <Avatar size={40}>
  //           <img src={Profile} alt="" />
  //         </Avatar>
  //         <div className="comment__section">
  //           <span className="commentor__name">Erin Gonzales </span>
  //           <span className="comment__text">has comment on your board</span>
  //         </div>
  //         <span className="comment__time">7:57PM</span>
  //       </div>
  //     </Menu.Item>
  //   </Menu>
  // );

  // const menuLanguage = (
  //   <Menu>
  //     <Menu.Item>
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.antgroup.com"
  //       >
  //         English
  //       </a>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.aliyun.com"
  //       >
  //         Japanese
  //       </a>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.luohanacademy.com"
  //       >
  //         Korean
  //       </a>
  //     </Menu.Item>
  //   </Menu>
  // );

  const handleRedirect = () => {
    // console.log('clicked')
    window.location.href = `${process.env.PUBLIC_URL}health-ui-${storageInfo ? JSON.parse(storageInfo).health : ''}`;
    // const header = {
    //   headers: {
    //     Authorization: `Bearer ${storageInfo?JSON.parse(storageInfo).data.token:""}`,
    //     "Access-Control-Allow-Origin": "*"
    //   }
    // }
    // fetch('https://dev-meycocms.codefirm.net/health-ui', header)
    //   .then((res: any)=> res.redirect('https://dev-meycocms.codefirm.net/health-ui'))
    // .then((res: any) => {
    //   let link = 'https://dev-meycocms.codefirm.net/health-ui';
    //   let a = document.createElement('a');
    //   a.href = link;
    //   console.log(res)
    //   document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    //   a.click();
    //   a.remove();
    // })
  };

  const menuProfile = (
    <Menu className="profile__dropdown">
      <Menu.Item key="0">
        <div className="profile__section">
          <Avatar size={40} icon={<UserOutlined />}></Avatar>
          <div>
            <h4 className="user__name">{storageInfo && JSON.parse(storageInfo).data.email}</h4>
            <span className="work__department">{storageInfo && JSON.parse(storageInfo).data.role}</span>
          </div>
        </div>
      </Menu.Item>
      {/* <Menu.Item key="1">
        <Link
          to="/admin"
        >
          <span className="link__iocns">
            <EditOutlined />
          </span>
          Admin Config
        </Link>
      </Menu.Item> */}
      <Menu.Item key="1">
        <Link to="/account-settings">
          <span className="link__iocns">
            {storageInfo && JSON.parse(storageInfo).isAdmin ? <SearchOutlined /> : <UserOutlined />}
          </span>
          {storageInfo && JSON.parse(storageInfo).isAdmin ? 'Account Settings' : 'User Info'}
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/change-password">
          <span className="link__iocns">
            <LockOutlined />
          </span>
          Change Password
        </Link>
      </Menu.Item>
      {storageInfo && JSON.parse(storageInfo).isAdmin ? (
        <Menu.Item key="7">
          <a
            href={`${process.env.REACT_APP_BASE_URL}/health-ui-${storageInfo ? JSON.parse(storageInfo).health : ''}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="link__iocns">
              <HeartOutlined />
            </span>
            Health UI
          </a>
        </Menu.Item>
      ) : (
        ''
      )}
      {storageInfo && JSON.parse(storageInfo).isAdmin ? (
        <Menu.Item key="3">
          {/* <CheckPermission has="RoleRead"> */}
          <>
            <Link to="/roles">
              <span className="link__iocns">
                <KeyOutlined />
              </span>
              Roles/Permission Config
            </Link>
          </>
          {/* </CheckPermission> */}
        </Menu.Item>
      ) : (
        ''
      )}

      {storageInfo && JSON.parse(storageInfo).isAdmin ? (
        <Menu.Item key="3">
          {/* <CheckPermission has="RoleRead"> */}
          <>
            <Link to="#" onClick={uploadPricingPdfHandler}>
              <span className="link__iocns">
                <UploadOutlined />
              </span>
              Upload Pricing PDF/ZIP File
            </Link>
          </>
          {/* </CheckPermission> */}
        </Menu.Item>
      ) : (
        ''
      )}

      {storageInfo && JSON.parse(storageInfo).isAdmin ? (
        <Menu.Item key="9">
          {/* <CheckPermission has="RoleRead"> */}
          <>
            <Link to="/activity-log">
              <span className="link__iocns">
                <UserOutlined />
              </span>
              Activity Log
            </Link>
          </>
          {/* </CheckPermission> */}
        </Menu.Item>
      ) : (
        ''
      )}
      {/* <Menu.Item key="3">
        <Link
          to="#"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          <span className="link__iocns">
            <ShopOutlined />
          </span>
          Billing
        </Link>
      </Menu.Item> */}
      <Menu.Item key="4">
        <Link to="/help">
          <span className="link__iocns">
            <QuestionCircleOutlined />
          </span>
          Help/ FAQ
        </Link>
      </Menu.Item>
      <Menu.Item key="5">
        <span className="theme__switch__row">
          <BulbOutlined />
          Dark theme
          <Switch checked={getTheme() === ThemeList.DARK} onChange={changeThemeApp} />
        </span>
      </Menu.Item>
      <Menu.Item key="6">
        <Link to="#" onClick={signOutHandler}>
          <span className="link__iocns">
            <LogoutOutlined />
          </span>
          Signout
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="page__header">
        <div className="header__logo">
          <img src={Logo} alt="" />
        </div>
        <div className="header__main">
          <div className={`responsive__search ${showResponsiveSearch ? 'show' : ''}`}>
            <div className="search__wrapper">
              <Button>
                <SearchOutlined />
              </Button>
              <Input placeholder="Basic usage" />
            </div>
            <Button onClick={handleResponsiveSearchClose} className="btn__close__search">
              <CloseOutlined />
            </Button>
          </div>
          <Button onClick={props.showDrawer} className="btn__menu__toggle drawer__trigger">
            <MenuUnfoldOutlined />
          </Button>
          {/* <div className="search__wrapper">
          <Button>
            <SearchOutlined />
          </Button>
          <Input placeholder="Basic usage" />
        </div> */}
          <ul className="navigation__right">
            <li className="display__lg">
              <Button className="btn__search__sm" onClick={handleResponsiveSearch}>
                <SearchOutlined />
              </Button>
            </li>
            {/* <li>
            <Dropdown
              overlay={menuNotification}
              placement="bottomRight"
              arrow
              trigger={["click"]}
            >
              <Button className="btn__notification">
                <BellOutlined />
                <Badge count={5} />
              </Button>
            </Dropdown>
          </li> */}
            <li>
              <Dropdown overlay={menuProfile} placement="bottomRight" arrow trigger={['click']}>
                <Button className="btn__profile">
                  <Avatar size={50} icon={<UserOutlined />}></Avatar>
                </Button>
              </Dropdown>
            </li>
          </ul>
        </div>
      </div>
      <PricingPdf showUplaodPricingPdfModal={showUplaodPricingPdfModal} handleClose={handleClose} />
    </>
  );
};

export default Navbar;
