// import { IAdminData } from "./../../interfaces/admin.interface";
// import { IHttp, IHttpResponse } from "./../../interfaces/http.interface";
import axios from 'axios';
import { head } from 'lodash';
import axiosInstance from '../../axios/axios';
import { getUserFromStorage } from '../../util/localStorage.util';
import * as adminActions from './adminActions';
const storageInfo = getUserFromStorage();

const header = {
  headers: {
    Authorization: `Bearer ${storageInfo ? JSON.parse(storageInfo).data.token : ''}` //the token is a variable which holds the token
  }
};

export const passUserDetail = (user: any) => {
  // some aync logic
  return {
    type: adminActions.USER_DETAIL,
    user: user
  };
};

export const getAllAdmin = async (data: any): Promise<[any, any]> => {
  try {
    const response = await axiosInstance.get(
      `/admin/list?PageNumber=${data.pageIndex}&PageSize=${data.pageSize}&OrderBy=${data.OrderBy}&Search=${data.Search}`,
      header
    );
    return [response.data.data, null];
  } catch (err) {
    return [null, err];
  }
};

export const addNewAdmin = async (data: any) => {
  try {
    const response = await axiosInstance.post('/admin/add', data, header);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const editAdmin = async (data: any) => {
  try {
    const response = await axiosInstance.put('/admin/updatefromusermanagement', data, header);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const updateAdminSetting = async (data: any) => {
  try {
    const response = await axiosInstance.put('/admin/update', data, header);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const deleteAdmin = async (id: any) => {
  try {
    const response = await axiosInstance.delete(`/admin/${id}`, header);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const activeChange = async (body: any) => {
  try {
    const response = await axiosInstance.put(`/admin/isactivechange/`, body, header);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getAdminInfo = async (id: any) => {
  try {
    const response = await axiosInstance.get(`/admin/${id}`, header);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getStateList = async () => {
  try {
    const response = await axiosInstance.get(`/states/list`, header);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getCityList = async (code: string) => {
  try {
    const response = await axiosInstance.get(`/cities/list?StateId=${code}`, header);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const changePassword = async (data: {}) => {
  try {
    const response = await axiosInstance.post(`/auth/change-password`, data, header);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getAllRoles = async () => {
  try {
    const response = await axiosInstance.get(`/Roles`, header);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getRolePermission = async (role: string) => {
  try {
    const response = await axiosInstance.get(`/Roles/${role}`, header);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const updatePermission = async (data: any) => {
  try {
    const response = await axiosInstance.post(`/Roles/update-role-permission`, data, header);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const getActivityLog = async (data: any) => {
  try {
    const response = await axiosInstance.get(`/Activity/List`, { headers: header.headers, params: { ...data } });
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const activityDownload = async (data: any) => {
  try {
    const response = await axiosInstance.get(`/Activity/Export`, { headers: header.headers, params: { ...data } });
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const uploadPdf = async (data: any) => {
  try {
    const response = await axiosInstance.post(`/pricingpdf/pdfupload`, data, header);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};

export const uploadZip = async (data: any) => {
  try {
    const response = await axiosInstance.post(`/pricingpdf/zipupload`, data, header);
    return [response, null];
  } catch (err) {
    return [null, err];
  }
};
