import { ILoginData } from "./../interfaces/auth.interface";
import { LocalStorageKeys } from "../enum/enum";

export const getUserFromStorage = (): string | null => {
  return localStorage.getItem(LocalStorageKeys.USER);
};

export const clearLocalStorage = () => {
  localStorage.removeItem(LocalStorageKeys.USER);
};

export const addAuthToLocalStorage = (user: ILoginData) => {
  localStorage.setItem(LocalStorageKeys.USER, JSON.stringify(user));
};

export const getTheme = (): string | null => {
  return localStorage.getItem(LocalStorageKeys.THEME);
};

export const setTheme = (theme: string) => {
  localStorage.setItem(LocalStorageKeys.THEME, theme);
};

export const setAccount = (account: string) => {
  localStorage.setItem(LocalStorageKeys.ACCOUNT, account);
}

export const getAccount = (): string | null => {
  return localStorage.getItem(LocalStorageKeys.ACCOUNT);
}