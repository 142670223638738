import { ReactNode } from 'react';
import { Route } from 'react-router';
import { IRouteItem } from '../interfaces';
import { getUserFromStorage } from '../util/localStorage.util';
import { lazy } from './lazy';

const storageInfo = getUserFromStorage();

export const mainRoutesList: IRouteItem[] = [
  {
    name: 'Order',
    path: '/',
    LazyComponent: lazy(() => import('../pages/Order/Order')),
    exact: true
  },
  {
    name: 'Form',
    path: '/form',
    LazyComponent: lazy(() => import('../pages/Form/Form')),
    exact: true
  },
  {
    name: 'Table',
    path: '/tables',
    LazyComponent: lazy(() => import('../pages/Table/Table')),
    exact: true
  },
  {
    name: 'Admin',
    path: '/admin',
    LazyComponent: lazy(() => import('../pages/Admin/Admin')),
    exact: true
  },
  {
    name: 'User',
    path: '/user',
    LazyComponent: lazy(() => import('../pages/User/User')),
    exact: true
  },
  {
    name: 'Change Password',
    path: '/change-password',
    LazyComponent: lazy(() => import('../pages/Change-Password/ChangePassword')),
    exact: true
  },
  {
    name: 'Customer Register',
    path: '/account-settings',
    LazyComponent: lazy(() => import('../pages/Admin-Register/AdminRegister')),
    exact: true
  },
  {
    name: 'Order',
    path: '/orders',
    LazyComponent: lazy(() => import('../pages/Order/Order')),
    exact: true
  },
  // {
  //   name: 'Order Material',
  //   path: '/order-material',
  //   LazyComponent: lazy(() => import('../pages/OrderMaterial/orderMaterial')),
  //   exact: true
  // },
  {
    name: 'Material PDF',
    path: '/material-pdf',
    LazyComponent: lazy(() => import('../pages/Pdf-download/pdf-download')),
    exact: true
  },
  {
    name: 'Roles',
    path: '/roles',
    LazyComponent: lazy(() => import('../pages/Roles-Permission/Roles')),
    exact: true
  },
  {
    name: 'Help',
    path: '/help',
    LazyComponent: lazy(() => import('../pages/Help/help')),
    exact: true
  },
  {
    name: 'Activity Log',
    path: '/activity-log',
    LazyComponent: lazy(() => import('../pages/ActivityLog/activityLog')),
    exact: true
  },
  {
    name: 'Pricing PDF',
    path: '/pricing-pdf',
    LazyComponent: lazy(() => import('../pages/Pricing-Pdf/PricingPdf')),
    exact: true
  }
];

const routerDom: ReactNode[] = [];
const parseRoutes = (routeList: IRouteItem[]) => {
  routeList.forEach((route, Key) => {
    if (storageInfo) {
      if (!JSON.parse(storageInfo).isAdmin) {
        if (
          route.name === 'User' ||
          route.name === 'Admin' ||
          route.name === 'Roles' ||
          route.name === 'Activity Log'
        ) {
          return null;
        }
      }
      if (JSON.parse(storageInfo).isAdmin) {
        if (route.name === 'Material PDF') {
          return null;
        }
      }
    }
    return routerDom.push(
      <Route key={Key} {...route} children={props => <route.LazyComponent {...props} route={route} />} />
    );
  });
  return routerDom;
};

export const mainRoutes = parseRoutes(mainRoutesList);
